.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  outline: none;
  font-size: 18px;
}

.react-datepicker-wrapper {
  border: 1px solid #cbd2da;
  height: 48px;
  padding-left: 20px;
}
.react-datepicker-wrapper--error {
  border: 1px solid #ff92ac;
  height: 48px;
  padding-left: 20px;
}

.react-datepicker {
  font-size: 1em;
  font-family: 'Open Sans';
}

.react-datepicker__header {
  padding-top: 0.8em;
}

.react-datepicker__month {
}

.react-datepicker__day-name,
.react-datepicker__day {
  width: 1.9em;
  line-height: 1.9em;
  margin: 0.166em;
}

.react-datepicker__current-month {
  font-size: 1em;
  text-align: left;
  padding-left: 10px;
}

.react-datepicker__navigation {
  top: 1em;
  line-height: 1.7em;
  border: 0.45em solid transparent;
}

.react-datepicker__navigation--previous {
  left: 1em;
}

.react-datepicker__navigation--next {
  right: 1em;
}
.react-datepicker__triangle {
  display: none;
}

.react-datepicker__header {
  background-color: white;
  border-bottom: none;
}
.react-datepicker__day-names {
  display: flex;
  justify-content: space-between;
  margin: 0 15px;
}
.react-datepicker__day-name {
  text-transform: capitalize;
  font-weight: bold;
  font-family: 'Open Sans';
}

.react-datepicker__month {
  margin: 0 18px 18px 18px;
}
.react-datepicker__day {
  width: auto;
  min-width: 21px;
}
.react-datepicker__week {
  display: flex;
  column-gap: 20px;
  justify-content: space-around;
  text-align: center;
}
.react-datepicker__header--custom {
  padding: 15px 0 0 0;
  border-radius: 10px;
}
.react-datepicker {
  border-radius: 10px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #0000007a;
}

.react-datepicker__day--keyboard-selected {
  background-color: transparent;
  color: black;
}
.react-datepicker__month-container {
}

.customDatepickerHeader {
}
/*---defaultView---*/
.customDatepickerHeader__defaultView {
  font-size: 20px;
}

.customDatepickerHeader__defaultView__year button {
  font-family: 'Open Sans';
  font-size: 20px;
  color: #1f2933;
}

.customDatepickerHeader__defaultView__buttons button {
  font-weight: bold;
}
.customDatepickerHeader__defaultView__month {
  text-transform: capitalize;
  color: #1f2933;
  font-family: 'Open Sans';
}

.customDatepickerHeader__defaultView__wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 24px 0 18px;
}

.customDatepickerHeader__defaultView__buttons {
  font-size: 22px;
}

.customDatepickerHeader__defaultView__buttons button {
  margin: 0 5px;
  color: #1f2933;
  font-family: Open Sans, Bold;
}
.react-datepicker__day-names {
  display: none;
}
.customDatepickerHeader__defaultView__weekDays {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 18px;
}
.customDatepickerHeader__defaultView__weekDays__day {
  font-family: 'Open Sans';
  font-size: 18px;
  font-weight: 600;
}
/*---defaultView---*/

/*---monthView---*/
.customDatepickerHeader__monthView {
  /*margin-top: 15px;*/
  margin-bottom: 36px;
}
.customDatepickerHeader__monthView button {
  font-family: 'Open Sans';
  font-size: 20px;
  color: #1f2933;
}
.react-datepicker__monthPicker {
  margin: 0 28px 28px 28px;
  display: flex;
  flex-direction: column;
  gap: 26px;
}
.react-datepicker__month-wrapper {
  display: flex;
  justify-content: space-between;
}
.react-datepicker__month-text {
  font-family: 'Open Sans';
  font-size: 18px;
  text-transform: capitalize;
}

.react-datepicker__month-text--keyboard-selected {
  background-color: transparent;
  color: #1f2933;
}

/*---monthView---*/

/*---yearView---*/
.react-datepicker__year--container,
.react-datepicker__month-container {
  width: 308px;
}
.react-datepicker__year--container .react-datepicker__header--custom {
  padding-top: 0;
  border-radius: 10px;
}
.react-datepicker__header:not(.react-datepicker__header--has-time-select) {
  border-radius: 10px;
}
.customDatepickerHeader__yearView__buttons {
  display: flex;
  justify-content: flex-end;
  margin-right: 24px;
}
.customDatepickerHeader__yearView__buttons button {
  margin: 0 5px;
  font-family: Open Sans, Bold;
  font-size: 22px;
  color: #1f2933;
  font-weight: bold;
}
.react-datepicker__year {
  margin: 0px 30px 36px 30px;
}
.react-datepicker__year-wrapper {
  row-gap: 18.5px;
  column-gap: 48.5px;
  max-width: 308px;
}

.react-datepicker__year-text--keyboard-selected {
  background-color: transparent;
  color: black;
}
.react-datepicker__year-text--keyboard-selected:hover {
  background-color: #f0f0f0;
}

.customDatepickerHeader__yearView__yearsRange {
  margin-top: 15px;
}
.customDatepickerHeader__yearView__yearsRange p {
  font-family: Open Sans, Bold;
  font-size: 20px;
}

/*---yearView---*/
.react-datepicker__year-text {
  white-space: nowrap;
}

.react-datepicker__year-text,
.react-datepicker__day {
  font-size: 18px;
  font-family: 'Open Sans';
  margin: 0;
}

.react-datepicker__month--selected,
.react-datepicker__year-text--selected {
  background-color: transparent;
  color: black;
  font-weight: bold;
  position: relative;
}

.react-datepicker__month-text.react-datepicker__month--selected:hover,
.react-datepicker__day--selected:hover,
.react-datepicker__year-text--selected:hover {
  background-color: #f0f0f0;
}
.react-datepicker__day--outside-month,
.react-datepicker__day--disabled {
  color: #1f2933;
  opacity: 40%;
  font-size: 18px;
  font-family: 'Open Sans';
}

.react-datepicker__year-text {
  width: auto !important;
  min-width: 46px;
}
.react-datepicker__month-text--today,
.react-datepicker__day--today,
.react-datepicker__year-text--today {
  font-weight: bold;
  color: #0095da;
}

.react-datepicker__day--keyboard-selected:hover {
  background-color: #f0f0f0;
}
.react-datepicker__day--selected {
  background-color: transparent;
  color: black;
  font-weight: bold;
  position: relative;
}
.react-datepicker__month--selected::after,
.react-datepicker__year-text--selected::after,
.react-datepicker__day--selected::after {
  content: '';
  width: 100%;
  height: 5px;
  background-color: #0095da;
  position: absolute;
  bottom: 0;
  left: 0;
  border-radius: 5px;
}
.react-datepicker__month--selected::after,
.react-datepicker__year-text--selected::after {
  bottom: -3px;
  width: 100%;
  margin: 0 auto;
  bottom: -5px;
}
