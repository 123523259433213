span.chakra-checkbox__control {
  width: 22px;
  height: 22px;
  border-radius: 4px;
  border: 1px solid #cbd2da;
}

span.css-xxkadm[data-focus],
span.css-1oi6yiz[data-focus] {
  box-shadow: none;
}

span.css-xxkadm[data-checked],
span.css-1oi6yiz[data-checked],
span.css-xxkadm[data-checked][data-hover],
span.css-1oi6yiz[data-checked][data-hover] {
  background: none;
  color: #1f2933;
  border: 1px solid #cbd2da;
}

span.chakra-switch__track,
span.css-ddil6j[data-focus] {
  box-shadow: none;
  background: #cbd2da;
}

span.chakra-switch__track[data-checked] {
  background: #0095da;
}

svg.css-1r69mki {
  width: 20px;
  height: 10px;
}
